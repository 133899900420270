<template>
  <div
    class="grid grid-cols-2-small"
    :class="{ 'grid-cols-2-menu': activeParent }"
  >
    <!-- header del sistema -->
    <Nav v-if="route.name !== 'login'" />
    <main class="flex-grow" :class="{'dashboard-bg':route.name ==='HomePage'}">
      <HeaderHome v-if="route.name !== 'login'" @actualizar-propiedad="activeParent = $event" />
      <!-- espacio donde se va a rederisar todos los componentes -->
      <router-view class="pl-4 pr-6 pt-5 pb-8"> </router-view>
    </main>
  </div>
  <div id="container-pdf"></div>
</template>

<script>
import Nav from "./components/Nav/Nav.vue";
import HeaderHome from "./components/Nav/Header.vue";
import { useRoute } from 'vue-router'
export default {
  name: "App",
  components: {
    Nav,
    HeaderHome,
  },
  data() {
    return {
      activeParent: false,
    };
  },setup() {
    const route = useRoute()

    return {route}
  },
};
</script>
<style>
.grid-cols-2-small {
  grid-template-columns: 255px 1fr;
}
.grid-cols-2-small.grid-cols-2-menu {
  grid-template-columns: 70px 1fr;
  transition: all 0.25s;
}
.grid-cols-2-small.grid-cols-2-menu .small-hidden {
  display: none;
}
.grid-cols-2-small.grid-cols-2-menu .small-center {
  justify-content: center;
}
@media screen and (max-width: 720px) {
  .grid-cols-2-small {
    grid-template-columns: 0 1fr;
  }
  .grid-cols-2-small {
    grid-template-columns: 0 1fr;
    transition: all 0.25s;
  }
  .grid-cols-2-small .small-hidden {
    display: none;
  }
  .grid-cols-2-small .small-center {
    justify-content: center;
  }
  .grid-cols-2-small.grid-cols-2-menu {
    grid-template-columns: 200px 1fr;
    transition: all 0.25s;
  }

  .grid-cols-2-small.grid-cols-2-menu .small-hidden {
    display: inline-flex;
  }
  .grid-cols-2-small.grid-cols-2-menu .small-center {
    justify-content: start;
  }
}
/* Eliminar las flechas del input number */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: none;
}
.dashboard-bg{
  background-color: #fafdff;
}
.select {
  background-color: #008cff !important;
  color: #ffffff;
}
</style>
