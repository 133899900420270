<template>
  <div class="mt-4">
    <div class="flex justify-between flex-wrap md:flex-nowrap items-end mb-6">
      <div class="flex flex-wrap items-center gap-3">
        <button
          class="bg-blue-500 text-white py-2 px-3 rounded-lg flex items-center outline-none shadow-sm hover:shadow-blue-500"
          @click="activeModalRegister"
          v-if="dataForm.canYouAddProduct"
        >
          <font-awesome-icon icon="fa-solid fa-plus" class="mr-2" />
          Nuevo registro
        </button>
        <button
          class="bg-green-100 text-green-500 py-2 px-3 rounded-lg flex items-center outline-none shadow-sm hover:bg-green-500 hover:text-white"
          @click="activeImportProduct"
        >
          <font-awesome-icon icon="fa-solid fa-file-excel" class="mr-2" />
          Importar productos
        </button>
        <RouterLink to="/lista-de-precio-productos"
          class="bg-gray-100 text-gray-500 py-2 px-3 rounded-lg flex items-center outline-none shadow-sm hover:bg-gray-500 hover:text-white"
        >
          <font-awesome-icon icon="fa-solid fa-dollar" class="mr-2" />
          Lista de precios
        </RouterLink>
        <!-- acciones de impresion -->
        <div class="relative group">
          <button
            class="bg-blue-100 flex items-center justify-center w-9 h-9 rounded-md text-blue-600"
          >
            <font-awesome-icon icon="fa-solid fa-ellipsis" class="rotate-90" />
          </button>
          <ul
            class="absolute top-full left-0 bg-white rounded-md shadow-lg overflow-hidden transition-all duration-300 transform scale-0 group-hover:scale-100"
          >
            <button
              class="px-6 py-2 text-orange-500 flex items-center hover:bg-orange-50 whitespace-nowrap w-full"
              @click="exportPDF(registers, idstore)"
            >
              <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
              Exportar a PDF
            </button>
            <button
              class="px-6 py-2 text-green-500 flex items-center hover:bg-green-50 whitespace-nowrap w-full"
              @click="exportToAxcel(registers, idstore)"
            >
              <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
              Exportar a excel
            </button>
          </ul>
        </div>
        <!-- fin acciones de impresion -->
      </div>

      <!-- componente para las sucursales -->
      <label class="block mt-3 md:mt-0 w-full md:w-auto">
        <span class="block text-gray-600 font-semibold mb-1">Sucursales</span>
        <select
          v-model="idstore"
          @change="resetValuesAndGetData"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold w-full"
          id="store-name"
        >
          <option
            :value="dataStore.id"
            v-for="(dataStore, i) in dataStores"
            :key="i"
          >
            {{ dataStore.razon_social }}
          </option>
        </select>
      </label>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <!-- secction para filtar por limite de registro -->
      <div class="py-4 px-3 flex items-center justify-between">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold"
          @change="resetValuesAndGetData"
        >
          <option value="10">10 Registros</option>
          <option value="25">25 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
          <option value="1000">1000 Registros</option>
          <option value="2000">2000 Registros</option>
          <option value="3000">3000 Registros</option>
          <option value="5000">5000 Registros</option>
          <option value="7000">7000 Registros</option>
        </select>
        <div class="w-full">
          <label for="modules">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              placeholder="Buscar por nombre, codigo de barra o tipo de producto..."
              v-model="search"
              @keydown.enter="resetValuesAndGetData"
            />
          </label>
        </div>
      </div>

      <!-- seccion de conteido de datos tablas
                    1: Para poner una tabla responsiva añadir lo siguiente:
                      1.1: class="resize-generate"
                      1.2: ref="widthNav" 
                      1.3: @resize="handleResize"
                    -->
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="18" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                @click="selectRow($event)" @dblclick="duplicateProduct(register)"
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap bg-blue-50 text-blue-500"
                >
                  {{ register.codigo_de_barra }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 flex items-center whitespace-nowrap"
                >
                  <div class="w-8 h-8 mr-2">
                    <div class="w-8 h-8 rounded-lg overflow-hidden">
                      <img
                        src="@/assets/images/defaults/image-not-found.png"
                        :alt="register.nombre_producto"
                        v-if="register.poster === null"
                      />
                      <img
                        :src="APIURL + register.poster"
                        :alt="register.nombre_producto"
                        v-else
                      />
                    </div>
                  </div>
                  <span>
                    {{ register.nombre_producto }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span class="px-3 py-1 rounded-lg bg-stone-50 text-stone-500">
                    {{ register.igv }} %
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span
                    class="px-3 py-1 rounded-lg bg-orange-50 text-orange-700"
                  >
                    {{ register.utilidad }} %
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.precio_de_compra) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.precio_de_venta) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span
                    class="px-3 py-1 rounded-lg"
                    :class="{
                      'bg-red-50 text-red-700':
                        parseFloat(register.stock) <=
                        parseFloat(register.stock_minimo),
                      'bg-green-50 text-green-700':
                        parseFloat(register.stock) >
                        parseFloat(register.stock_minimo),
                    }"
                  >
                    {{ register.stock }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span class="px-3 py-1 rounded-lg bg-blue-50 text-blue-600">
                    {{ register.stock_minimo }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span
                    class="px-3 py-1 rounded-lg"
                    :class="{
                      'bg-green-50 text-green-600':
                        register.venta_con_stock === 1,
                      'bg-red-50 text-red-600': register.venta_con_stock === 0,
                    }"
                  >
                    {{ register.venta_con_stock === 0 ? "NO" : "SI" }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span
                    class="px-3 py-1 rounded-lg"
                    :class="{
                      'bg-orange-50 text-orange-600':
                        register.vencimiento !== null,
                    }"
                    v-if="register.vencimiento === null"
                  >
                    {{
                      register.vencimiento === null
                        ? "--/--/----"
                        : register.vencimiento
                    }}
                  </span>
                  <span
                    class="px-3 py-1 rounded-md text-sm"
                    v-else
                    :class="{
                      'bg-orange-50 text-orange-600': calculateExpirationDate(
                        register.vencimiento
                      ).includes('VENCE EN'),
                      'bg-orange-500 text-white': calculateExpirationDate(
                        register.vencimiento
                      ).includes('VENCE MAÑANA'),
                      'bg-red-50 text-red-600': calculateExpirationDate(
                        register.vencimiento
                      ).includes('VENCE HOY'),
                      'bg-red-500 text-white': calculateExpirationDate(
                        register.vencimiento
                      ).includes('VENCIDO'),
                      'bg-green-50 text-green-600':
                        calculateExpirationDate(register.vencimiento).includes(
                          '-'
                        ) ||
                        calculateExpirationDate(register.vencimiento).includes(
                          '/'
                        ),
                    }"
                  >
                    {{ calculateExpirationDate(register.vencimiento) }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.libre_de_impuesto }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.facturado }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.ubicacion }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <a
                    :href="APIURL + register.ficha_tecnica"
                    target="_blank"
                    v-if="register.ficha_tecnica !== null"
                    class="bg-blue-50 text-blue-500 px-3 py-1 rounded-lg"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-download"
                      class="mr-1"
                    />
                    Ficha técnica
                  </a>
                  <span v-else> --- </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <a
                    :href="APIURL + register.registro_sanitario"
                    target="_blank"
                    v-if="register.registro_sanitario !== null"
                    class="bg-blue-50 text-blue-500 px-3 py-1 rounded-lg"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-download"
                      class="mr-1"
                    />
                    Registro sanitario
                  </a>
                  <span v-else> --- </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span
                    class="px-3 py-1 rounded-lg text-sm"
                    :class="{
                      'bg-green-50 text-green-700': register.estado === 1,
                      'bg-red-50 text-red-700': register.estado === 0,
                    }"
                  >
                    {{ register.estado === 1 ? "activo" : "inactivo" }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 text-right whitespace-nowrap"
                >
                  <button
                    class="bg-orange-100 text-orange-500 hover:text-white py-1 px-2 rounded-md text-sm active:scale-105 hover:bg-orange-600 mr-2"
                    @click="
                      activeModalUpdateUnitMeasure(
                        register.id,
                        parseFloat(register.precio_de_compra)
                      )
                    "
                    v-if="dataForm.canYouAddUnitMeasure"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-folder-open"
                      class="w-3 h-3"
                    />
                  </button>
                  <button
                    class="bg-green-100 text-green-500 hover:text-white py-1 px-2 rounded-md text-sm active:scale-105 hover:bg-green-600 mr-2"
                    @click="
                      activeBarCode(
                        register.codigo_de_barra,
                        register.nombre_producto,
                        register.precio_de_venta
                      )
                    "
                    v-if="dataForm.canYouPrintBarcode"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-barcode"
                      class="w-3 h-3"
                    />
                  </button>
                  <button
                    class="bg-blue-100 text-blue-500 hover:text-white py-1 px-2 rounded-md text-sm active:scale-105 hover:bg-blue-700"
                    @click="activeModalUpdate(register.id)"
                    v-if="dataForm.canYouModifiProduct"
                  >
                    <font-awesome-icon icon="fa-solid fa-pen" class="w-3 h-3" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>

  <RegisterProducts
    :activeModal="activeModalRegister"
    :productDuplicate="product"
    :getdata="getData"
    v-if="stateRegister"
  />
  <UpdateProducts
    :activeModal="activeModalUpdate"
    :getdata="getData"
    :id="id"
    v-if="stateUpdate"
  />
  <MainProductsUnitMeasure
    :activeModal="activeModalUpdateUnitMeasure"
    :purchasePrice="purchasePrice"
    :id="id"
    v-if="stateUpdateUnitMasure"
  />

  <BarCode
    :activeModal="activeBarCode"
    :barCode="barCodeData.barCode"
    :productName="barCodeData.productName"
    :salePrice="barCodeData.salePrice"
    :company="barCodeData.company"
    v-if="stateBarCode"
  />

  <ImportProducts
    :activeModal="activeImportProduct"
    :getData="getData"
    v-if="stateImportProduct"
  />
</template>

<script>
import { onBeforeMount, onMounted, ref, watchEffect } from "vue";
import axios from "@/api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import RegisterProducts from "@/components/Store/Products/Main/RegisterProducts.vue";
import BarCode from "@/components/Store/Products/Main/BarCode.vue";
import UpdateProducts from "@/components/Store/Products/Main/UpdateProducts.vue";
import MainProductsUnitMeasure from "@/components/Store/Products/ProductsUnitMeasure/MainProductsUnitMeasure.vue";
import { errorActions, warningActions } from "@/alerts";
import { observer } from "@/observer";
import { money, format } from "@/publicjs/money";
import config from "/public/apis/config.json";
//pdfmake exportar productos a pdf
import { exportPDF } from "@/components/Store/Products/Reports/ProductsPDF";
import { exportToAxcel } from "@/components/Store/Products/Reports/ExcelReportOfAllProducts";
import { calculateExpirationDate } from "@/publicjs/calculateProductExpiration";
import { idLocalStore, idLocalUser } from "@/publicjs/localStorage";
import { validateAccessSpecific } from "@/repository/Sales/ValidateAccessEspecific/ValidateAccess";

import ImportProducts from "@/components/Store/Products/Main/ImportProducts.vue";
import { selectRow } from "@/publicjs/selectRowTable";

export default {
  name: "MainUnitMeasures",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
    RegisterProducts,
    UpdateProducts,
    MainProductsUnitMeasure,
    BarCode,
    ImportProducts,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "cod. barra",
        },
        {
          title: "descripción",
        },
        {
          title: config.iva,
        },
        {
          title: "utilidad",
        },
        {
          title: "p. de compra",
        },
        {
          title: "p. de venta",
        },
        {
          title: "stock",
        },
        {
          title: "stock minimo",
        },
        {
          title: "¿con stock?",
        },
        {
          title: "vencimiento",
        },
        {
          title: "L/I",
        },
        {
          title: "Facturado",
        },
        {
          title: "ubicación",
        },
        {
          title: "fic. técnica",
        },
        {
          title: "Reg. sanitario",
        },
        {
          title: "estado",
        },
        {
          title: "Acciones",
        },
      ],
    ];

    const dataForm = ref({
      canYouAddProduct: false,
      canYouModifiProduct: false,
      canYouAddUnitMeasure: false,
      canYouPrintBarcode: false,
    });

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(10);
    const search = ref("");
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const idstore = ref(0);
    const purchasePrice = ref(0);
    const product = ref(null);
    const getData = async () => {
      loading.value = true;
      const response = await axios
        .get(
          `productos/?page=${currentPage.value}&per_page=${limitRegister.value}&search=${search.value}&idstore=${idstore.value}`
        )
        .catch((error) => errorActions(error));

      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if ((await response.data.data.length) === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
        //codigo para evitar el error de [resize observer]
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
      }
      registers.value = await response.data.data;
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(
        () => {
          if (widthNav.value) {
            widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
          }
        }
        // { immediate: true }
      );
    };

    const id = ref(0);
    const activeModalUpdate = (idData) => {
      stateUpdate.value = !stateUpdate.value;
      id.value = idData;
    };

    const activeModalUpdateUnitMeasure = (idData = 0, pPrice = 0) => {
      stateUpdateUnitMasure.value = !stateUpdateUnitMasure.value;
      id.value = idData;
      purchasePrice.value = pPrice;
      product.value = null
    };

    // funcion para cargar las sucursales
    const dataStores = ref([]);
    const getStores = async () => {
      const response = await axios.get("sucursales").catch((error) => {
        errorActions(error);
      });
      if ((await response.data.data.length) === 0) {
        errorActions("no pudimos econtrar las sucursales");
      }
      dataStores.value = await response.data.data;
      idstore.value = dataStores.value[0].id;
    };

    onMounted(async () => {
      handleResize();
      window.addEventListener("resize", handleResize);

      const ACCESS = [
        "REGISTRAR PRODUCTOS",
        "MODIFICAR PRODUCTOS",
        "AGREGAR UNIDADES DE MEDIDA PRODUCTOS",
        "GENERAR CODIGO DE BARRA PRODUCTOS",
      ];

      const validate = await validateAccessSpecific(
        ACCESS,
        await idLocalUser()
      );

      if (validate.includes("REGISTRAR PRODUCTOS")) {
        dataForm.value.canYouAddProduct = true;
      }
      if (validate.includes("MODIFICAR PRODUCTOS")) {
        dataForm.value.canYouModifiProduct = true;
      }
      if (validate.includes("AGREGAR UNIDADES DE MEDIDA PRODUCTOS")) {
        dataForm.value.canYouAddUnitMeasure = true;
      }
      if (validate.includes("GENERAR CODIGO DE BARRA PRODUCTOS")) {
        dataForm.value.canYouPrintBarcode = true;
      }
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    //activar modal de registro y de actualizacion de datos
    const stateRegister = ref(false);
    const stateBarCode = ref(false);
    const stateUpdate = ref(false);
    const stateUpdateUnitMasure = ref(false);
    const stateImportProduct = ref(false);
    const activeModalRegister = () => {
      product.value = null;
      stateRegister.value = !stateRegister.value;
      
    };
    const duplicateProduct = (register=null) => {
      product.value = register;
      stateRegister.value = !stateRegister.value;
    }
    const activeImportProduct = () => {
      stateImportProduct.value = !stateImportProduct.value;
    };

    //URL PARA LOS RECURSO DE LA API COMO IMAGNES, ARCHIVOS, ETC

    const APIURL = process.env.VUE_APP_API_URL_RESOURCE;

    const barCodeData = ref({
      barCode: null,
      productName: null,
      salePrice: null,
      company: null,
    });
    const activeBarCode = (barCode, productName, salePrice) => {
      barCodeData.value.barCode = barCode;
      barCodeData.value.productName = productName;
      barCodeData.value.salePrice = salePrice;
      stateBarCode.value = !stateBarCode.value;

      const selectElement = document.getElementById("store-name");
      barCodeData.value.company =
        selectElement.options[selectElement.selectedIndex].text;
      selectElement.options[selectElement.selectedIndex].text;
    };
    onBeforeMount(async () => {
      await getStores();
      idstore.value = await idLocalStore();
      await getData();
    });

    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      search,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      activeModalRegister,
      duplicateProduct,
      product,
      stateRegister,
      activeModalUpdate,
      id,
      stateUpdate,
      widthNav,
      handleResize,
      money,
      format,
      APIURL,
      dataStores,
      idstore,
      activeModalUpdateUnitMeasure,
      stateUpdateUnitMasure,
      exportPDF,
      exportToAxcel,
      calculateExpirationDate,
      stateBarCode,
      activeBarCode,
      barCodeData,
      dataForm,
      activeImportProduct,
      stateImportProduct,
      selectRow,
      purchasePrice,
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
